div.footer {
	margin-bottom: 0;
	padding: 0rem;
}

.sidebar {
	min-width: 250px;
	max-width: 250px;
	transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
	background: transparent;
	border-right: 0;
	box-shadow: 0 0 2rem 0 rgb(0 0 0 / 5%);
	z-index: 1;
}

.sidebar-content {
	position: fixed;
	min-width: 250px;
	max-width: 250px;
	background: #fff;
	transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
	height: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

.sidebar-brand {
	padding: 1rem;
	background: #fff;
	font-size: 1.175rem;
	font-weight: 500;
	color: #212529;
	width: 100%;
	display: block;
	text-decoration: none;
}

.sidebar-nav {
	overflow-y: auto;
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	flex: 1;
	background: #fff;
}

.sidebar-nav::-webkit-scrollbar {
	width: 8px;
}

.sidebar-nav::-webkit-scrollbar-thumb {
	border-radius: 20px;
	border: 3px solid #d3d9fd;
}

.dropdown-toggle:after {
	color: #fff;
	margin-left: 10px;
	border: solid;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 2px;
	transform: rotate(45deg);
}

.sidebar-user {
	bottom: 0;
	padding: 0.5rem;
	background: #fff;
	color: #153d77;
	text-align: center;
}
.sidebar-user img {
	width: 54px;
	height: 54px;
	transition: 0.3s;
	margin-left: 10%;
}
.sidebar-user img:hover {
	transform: scale(1.25);
}

.ml-lg-1,
.mx-lg-1 {
	margin-left: 0.25rem !important;
	display: block;
}

.sidebar-dropdown .sidebar-item.active .sidebar-link {
	color: #212529;
	background: #fff;
	font-weight: 600;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 0.9375rem;
	color: #3e4676;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 2px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.2rem;
}

.dropdown-menu-right {
	left: 75% !important;
	top: -60% !important;
}

div.font-weight-bold {
	margin-left: 0.5rem;
}

.card-subtitle {
	font-weight: 400;
	padding: 0.5rem;
}

.btn-secondary {
	color: #fff;
	background-color: #3b7ddd;
	border-color: #3b7ddd;
}
.btn-secondary:hover {
	color: #fff;
	background-color: #3b7ddd;
	border-color: #3b7ddd;
}
.btn-secondary:focus,
.btn-secondary.focus {
	color: #fff;
	background-color: #3b7ddd;
	border-color: #3b7ddd;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
	color: #fff;
	background-color: #3b7ddd;
	border-color: #3b7ddd;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
	color: #fff;
	background-color: #3b7ddd;
	border-color: #3b7ddd;
}

.modal-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	border-bottom: 0px double #dee2e6;
	padding: 0.1rem 0.5rem;
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
}
.modal-header .close {
	padding: 1rem 1rem;
	margin: -1rem -1rem -1rem auto;
}

.modal-footer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	padding: 0.75rem;
	border-top: 0px solid #dee2e6;
	border-bottom-right-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
}
.modal-footer > * {
	margin: 0.25rem;
}

.sidebar-toggle {
	cursor: pointer;
	width: 26px;
	height: 26px;
	margin-left: 0.5rem;
	margin-top: 0.5rem;
}

div.row-btn-col2 {
	margin-left: 5rem;
	margin-right: 5rem;
}

button.inline-btn {
	width: 80px;
}

div.formSenha {
	margin-left: 10%;
	margin-right: 10%;
}

input.nova-senha {
	width: 100%;
}
