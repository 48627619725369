.content {
    margin-top: 0.5rem;
    padding: 0 0.5rem; }
    @media (min-width: 992px) {
      .content {
        padding: 0 0.5rem; } }


.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.5rem;
}

.card-header {
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}

.modal-header .close {
  padding: 0.5rem;
  font-size: 2.2rem;
  margin-top: -0.5rem;
  margin-right: 100rem;
}

.main{
  overflow-y: auto;
}

.modal-content {
  max-height: 90vh;;
  flex-direction: column;
  display: block;
  max-width: 70vw;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-content::-webkit-scrollbar {
  width: 8px;
  background:#fff
}

.modal-content::-webkit-scrollbar-thumb {
  border-radius: 20px;       
  border: 3px solid #d3d9fd;
}

.footer {
  padding: 0.0rem; }
  @media (min-width: 992px) {
    .footer {
      padding: 0.0rem;  } }
  .footer ul {
    margin-bottom: 0; }


    
.card {
  box-shadow: 0 2px 4px rgb(126 142 177 / 12%);
  margin-bottom: 1.0rem;
}

.table th, .table td{
  padding: 0.2rem;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

