
.LoginErro {
    color: #f04d4d;
}


div.bodyHome {
    margin: 0 auto;
    width: 30%;
}

.alert-msg{
    color: red;
}

.card.CardLogin{
    margin-bottom: 0rem;
}

div.footerLogin{
    text-align: right;
    font-weight: bold;
}

